// src/App.jsx
import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "../components/main";
import PrivacyPolicy from "../components/policies/privacy-policy";
import ShippingPolicy from "../components/policies/shipping-policy";
import PaymentPolicy from "../components/policies/payment-policy";
import TermsConditions from "../components/policies/terms-conditions";
import ReturnRefundPolicy from "../components/policies/returnRefundPolicy";
import AboutUs from "../components/policies/about-us";
import ContactUs from "../components/policies/contact-us";
import NotFound from "../components/policies/404";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/shipping-policy" element={<ShippingPolicy />} />
      <Route path="/payment-policy" element={<PaymentPolicy />} />
      <Route path="/terms-conditions" element={<TermsConditions />} />
      <Route path="/return-refund-policy" element={<ReturnRefundPolicy />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/404" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
