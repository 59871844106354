import { Helmet } from "react-helmet";
import Footer from "../main/footer";
import TopNav from "./top-nav";
import { useState } from "react";
import axios from "axios";
import { Oval } from "react-loader-spinner";

const ContactUs = () => {
  const [loader, setLoader] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  const resetForm = () => {
    setForm({
      name: "",
      email: "",
      message: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const sendEmail = async () => {
    if (form.name === "") {
      alert("Name is required");
      return;
    }

    if (form.email === "") {
      alert("Email is required");
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(form.email)) {
      alert("Email is not valid");
      return;
    }

    if (form.message === "") {
      alert("Message is required");
      return;
    }

    setLoader(true);

    setTimeout(() => {
      setLoader(false);
      setEmailSent(true);
    }, 3000);

    setTimeout(() => {
      setEmailSent(false);
    }, 5000);

    const apiKey =
      "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"; // Replace with your Brevo API Key
    const endpoint = "https://api.brevo.com/v3/smtp/email";

    const emailData = {
      sender: { name: "Felo", email: form.email }, // Use your own domain
      to: [{ email: "help@getfelo.com", name: "Felo Help" }], // Where you want to receive the email
      replyTo: { email: form.email, name: form.name }, // This is the user's email, so you can reply to it
      subject: `Contact`,
      htmlContent: `<p>${form.message}</p>`,
    };

    try {
      const response = await axios.post(endpoint, emailData, {
        headers: {
          "api-key": apiKey,
          "Content-Type": "application/json",
        },
      });
      resetForm();
      console.log("Email sent successfully:", response.data);
    } catch (error) {
      console.error(
        "Error sending email:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div>
      <Helmet>
        <title>Contact US</title>
        <meta
          name="description"
          content="We are always here to help. Please contact us by email with any questions you have."
        />
      </Helmet>
      <TopNav />
      <div class="spacer-xxlarge"></div>
      <div class="section_content">
        <h1 class="aux-top-heading">Talk To Us!</h1>
        <div class="aux-sub-heading">
          For any questions, issues, complaints or compliments please email us
          and we’ll be happy to assist you.
        </div>
        <div class="aux-divider"></div>
        <div
          id="w-node-e671db2f-3788-e023-eeca-54433f9cf759-3f9cf759"
          class="spacer-large"
        ></div>
        <div class="aux-field-box">
          <div class="aux-label-text">Email:</div>
          <div class="aux-email-text">help@getfelo.com</div>
        </div>
        <div class="spacer-small"></div>
        <div class="aux-field-box">
          <div class="aux-label-text">Our Office Address:</div>
          <div class="aux-email-text">
            601 Brickell Key Dr , Suite 700, Miami, FL 33131, United States
          </div>
        </div>
        <div class="contact-form-try">
          <div class="aux-label-text">Contact Us:</div>

          <div>
            <form>
              <label for="name" class="field-label">
                Name
              </label>

              <input
                class="w-input"
                maxlength="256"
                name="name"
                data-name="Name"
                placeholder=""
                type="text"
                id="name"
                value={form.name}
                onChange={handleInputChange}
              />
              <label for="email" class="field-label-2">
                Email Address
              </label>
              <input
                class="w-input"
                maxlength="256"
                name="email"
                placeholder=""
                type="text"
                id="email"
                value={form.email}
                onChange={handleInputChange}
              />
              <label for="field" class="field-label-2">
                Message
              </label>
              <textarea
                placeholder="Your Message"
                maxlength="5000"
                name="message"
                class="w-input"
                value={form.message}
                onChange={handleInputChange}
              ></textarea>
              <button
                type="button"
                data-wait="Please wait..."
                class="submit-button w-button w150"
                onClick={sendEmail}
              >
                {!loader && !emailSent ? (
                  "Send"
                ) : emailSent ? (
                  <span>
                    Email Sent <i class="fa-solid fa-check"></i>
                  </span>
                ) : (
                  <span>
                    <Oval
                      height="25"
                      width="25"
                      color="#ffffff"
                      secondaryColor="ffffff"
                      ariaLabel="oval-loading"
                      wrapperClass="loader-wrapper"
                    />
                  </span>
                )}
              </button>
            </form>
            <div class="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div class="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="spacer-xxlarge"></div>
      <Footer />
    </div>
  );
};

export default ContactUs;
